<template>
  <div>
    <div class="page" v-if="signer && !isSigner">
      <div class="page-title">
        <h1>署名登録または依頼</h1>
        <p>署名者へ依頼してください。</p>
      </div>
      <div class="card biz-primary" >
        <div class="card-header">
          署名依頼
          <div class="control">
            <router-link to="/supplier/manage/signer-info/edit">変更</router-link>
          </div>
        </div>
        <div
          v-if="!signer"
          class="card-body d-flex justify-content-center"
          style="min-height: 250px">
          <j-button
            variant="primary"
            to="/supplier/manage/signer-info/edit"
            style="align-self: center; justify-self: center"
            >署名者の登録</j-button>
        </div>
        <div v-if="signer" class="card-body">
          <div class="row">
            <div class="col"> 
              <div class="biz-form-group">
                <label class="biz-form-label">署名者</label>
                <div class="biz-form-body" v-if="signer">
                  <p class="biz-offset">{{ signer.jobTitle || "≪未登録≫" }}</p>
                  <p class="biz-offset">
                    <span>{{
                       signer.lastName + signer.firstName || "≪未登録≫"
                    }}</span>
                    <span class="ml-1"
                      >({{
                        signer.lastNameKana + signer.firstNameKana || "≪未登録≫"
                      }})</span
                    >
                    <span class="ml-1"
                      >({{
                        signer.lastNameEn + ' ' + signer.firstNameEn || "≪未登録≫"
                      }})</span
                    >
                  </p>
                  <p class="biz-offset">{{ signer.mobilePhone || "≪未登録≫" }}</p>
                  <p class="biz-offset">{{ signer.email || "≪未登録≫" }}</p>
                </div>
              </div>
            </div>
            <div class="col"> 
              <div class="biz-form-group">
                <label class="biz-form-label">代行者</label>
                <div class="biz-form-body" v-if="substitute">
                  <p class="biz-offset">{{ substitute.jobTitle || "≪未登録≫" }}</p>
                  <p class="biz-offset">
                    <span>{{
                      substitute.lastName + substitute.firstName || "≪未登録≫"
                    }}</span>
                    <span class="ml-1"
                      >({{
                        substitute.lastNameKana + substitute.firstNameKana || "≪未登録≫"
                      }})</span
                    >
                    <span class="ml-1"
                      >({{
                        substitute.lastNameEn + ' ' + substitute.firstNameEn || "≪未登録≫"
                      }})</span
                    >
                  </p>
                  <p class="biz-offset">{{ substitute.mobilePhone || "≪未登録≫" }}</p>
                  <p class="biz-offset">{{ substitute.email || "≪未登録≫" }}</p>
                </div>
                <div class="biz-form-body" v-else>
                  <p class="biz-offset">≪未登録≫</p>
                </div>
              </div>
            </div>
          </div>
          
          <div class="mt-3">
            <label class="biz-form-label">署名者への連絡事項</label>
            <b-form-textarea
              v-model="message"
            ></b-form-textarea>
          </div>
        </div>
      </div>
      <div class="mt-3 text-center">
        <j-button
            size="lg"
            variant="primary"
            v-on:click="request()"
            >署名を依頼</j-button>
        </div>
    </div>
    <div v-if="!signer">
      <SignerInfo :isRegistrationWizard="isRegistrationWizard" @save="onSignerSave"/>
    </div>
    <div v-if="signer && isSigner">
      <ContractInfo :isRegistrationWizard="isRegistrationWizard" @save="onContractComplete"/>
    </div>
  </div>
</template>

<script>
import UserInfo from "@/model/UserInfo.js";
import SignerInfo from "@/pages/manage/SignerInfo.vue";
import ContractInfo from "@/pages/manage/ContractInfo.vue";
export default {
  props:["isRegistrationWizard"],
  components: { SignerInfo, ContractInfo },
  data: function () {
    return {
      signer: null,
      substitute: null,
      message: "",
      isSigner: false
    };
  },
  mounted: function () {
    this.load();
  },
  methods:{
    request(){
      UserInfo.requestSign(this.$store.state.user.companyCode, {
        message: this.message
      }).then(()=>{
          this.$successMessage("署名者へ署名依頼メールをお送りしました。", "メールお送りされました。");
          this.$router.push({ name: "MainMenu" });
      }).catch(reason=>this.$errorMessage(undefined, {reason}));
    },
    load(){
        UserInfo.getSigner(this.$store.state.user.companyCode).then((result) => {
          this.signer = result.data.signer;
          this.substitute = result.data.substitute;
          //TODO needs proper role functions
          if(this.signer){
            if(this.$store.state.user.roles.find(r => r.roleCode == 'SB20')){
              this.isSigner = true;
            } 
            if(this.$store.state.user.displayCode === this.signer.email){
              this.isSigner = true;
            }
          }
          if(this.substitute){
            if(this.$store.state.user.roles.find(r => r.roleCode == 'SB20')){
              this.isSigner = true;
            } 
            if(this.$store.state.user.displayCode === this.substitute.email){
              this.isSigner = true;
            }
          }
        }).catch((reason) => this.$errorMessage(undefined, {reason}));
    },
    onSignerSave(){
      this.load();
    },
    onContractComplete(){
      
    }
  }
  
};
</script>

<style>
</style>